.unifaun-checkout-root {
    @apply flex flex-col text-black accent-black;
}
.unifaun-checkout-option-header0 {
    @apply grid grid-cols-[auto,1fr,auto] grid-rows-[auto,1fr] items-start gap-4 md:gap-x-8;
}

.unifaun-checkout-option0 {
    @apply grid cursor-pointer p-5 md:p-8;
}

.unifaun-checkout-option0:not(:last-child) {
    @apply border-b;
}

.unifaun-checkout-option-columns {
    @apply row-span-2;
}

.unifaun-checkout-option-column2 {
    @apply col-span-4 row-start-[-1] row-end-[-1] hidden;
}

.unifaun-checkout-selected0:has(.unifaun-checkout-option-agents-wrapper)
    > .unifaun-checkout-option-header0
    > .unifaun-checkout-option-columns
    > .unifaun-checkout-option-column2 {
    display: initial;
}

.unifaun-checkout-option-agents {
    @apply w-full cursor-pointer appearance-none border p-3;

    background: url('data:image/svg+xml,<svg width="1.25rem" height="1.25rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 9L12 15L6 9" stroke="currentColor" strokeWidth="2" strokeLinecap="square" /></svg>')
        no-repeat theme(colors[white]);
    background-position: calc(100% - theme(spacing.4)) center;
}

.unifaun-checkout-option-agent-panel {
    @apply overflow-hidden;
}

.unifaun-checkout-option0 .unifaun-checkout-clip-box {
    @apply hidden;
}

.unifaun-checkout-selected0 {
    @apply bg-gray-50;
}

.unifaun-checkout-selected0 .unifaun-checkout-clip-box {
    display: revert;
}

.unifaun-checkout-selected0:has(.unifaun-checkout-clip-box:not(:empty)) {
    @apply auto-rows-auto gap-y-4;
}

.unifaun-checkout-clip-box:has(.unifaun-checkout-option-sub-column1:empty) {
    @apply hidden;
}

.unifaun-checkout-selected0:has(.unifaun-checkout-option-agents-wrapper)
    > .unifaun-checkout-option-header0 {
    @apply gap-y-4;
}

.unifaun-checkout-option-prefix-icon {
    @apply col-start-3 row-start-2;
}

.unifaun-checkout-option-prefix-icon > img {
    @apply ml-auto max-w-10;
}

.unifaun-checkout-option-no-icons-spacer {
    @apply hidden;
}

.unifaun-checkout-option-radio {
    @apply relative mt-2 grid h-5 w-5;
}

.unifaun-checkout-option-radio > input,
.unifaun-checkout-option-radio > span {
    @apply col-span-full row-span-full flex h-full w-full transition-opacity duration-150;
}

.unifaun-checkout-option-radio > span {
    @apply bg-no-repeat opacity-0;
}

.is-loading .unifaun-checkout-selected0 .unifaun-checkout-option-radio {
    @apply scale-110;
}

.is-loading .unifaun-checkout-selected0 .unifaun-checkout-option-radio > input {
    @apply opacity-0;
}

.is-loading .unifaun-checkout-selected0 .unifaun-checkout-option-radio > span {
    @apply animate-spin opacity-100;

    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>');
}

.unifaun-checkout-option-title {
    @apply text-base font-normal;
}

.unifaun-checkout-option-description1 {
    @apply text-sm font-normal text-gray-500;
}

.unifaun-checkout-option-delivery {
    @apply text-sm font-normal;
}

.unifaun-checkout-addon-container {
    @apply flex flex-col;
}

.unifaun-checkout-addon > label {
    @apply grid w-full grid-cols-[auto,auto,1fr,auto] gap-x-2;
}

.unifaun-checkout-custom-checkbox {
    @apply hidden;
}

.unifaun-checkout-text-input {
    @apply relative block;
}

.unifaun-checkout-option-fields-panel {
    @apply grid auto-rows-auto gap-y-4;
}

.unifaun-checkout-option-fields-panel > div:not([class]) {
    @apply grid grid-flow-row auto-rows-auto gap-y-4;
}

.unifaun-checkout-option-fields-panel > div:empty {
    @apply hidden;
}

.unifaun-checkout-addon-container {
    @apply grid auto-rows-auto gap-y-2;
}

.unifaun-checkout-text-input > label {
    @apply pointer-events-none absolute left-2 top-1/2 -translate-y-1/2 transform bg-gray-50 p-2 text-gray-500 transition-all;
}

.unifaun-checkout-text-input:focus-within > label,
.unifaun-checkout-text-input:has(input:not([value=''])) > label {
    @apply top-0 text-xs text-black;
}

.unifaun-checkout-text-input > input {
    @apply w-full border border-solid border-black bg-transparent px-2 py-4;
}

.unifaun-checkout-text-input:has(input:disabled) {
    @apply opacity-30;
}

.unifaun-checkout-text-input:has(input:is([value='']))
    > .unifaun-checkout-text-input-label-message,
.unifaun-checkout-text-input:focus-within
    > .unifaun-checkout-text-input-label-message {
    @apply hidden;
}

.unifaun-checkout-text-input-label-message {
    @apply absolute left-2 top-full text-xs text-error;
}

.unifaun-checkout-option-column1-text {
    @apply col-span-3 flex flex-col gap-y-2;
}

.unifaun-checkout-option-column1-text > br {
    @apply hidden;
}

.unifaun-checkout-option-separator {
    @apply hidden;
}

.unifaun-checkout-option-price {
    @apply text-end;
}

.unifaun-checkout-option-price > span {
    @apply whitespace-nowrap;
}
